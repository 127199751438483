<template>
  <!-- #region 引导关注 -->
  <div class="direct_attention">
    <div class="direct_title">了解济南中小企业</div>
    <div class="direct_icon"></div>
    <p class="direct_content">“小微企业之家”是济南市中小企业公共服务中心创建的公共服务品牌。以“服务企业，助力成长”为主线，以建设广大中小微企业找得到，信得过，用得起，有保障的公益服务平台为目标</p>
    <div class="direct_btn" @click=";(showViewer = true), stopMove()">扫描下方二维码关注我们</div>
    <div class="direct_qr_code">
      <img src="@/assets/images/footer/app.png" alt="" />
    </div>
    <el-image-viewer v-if="showViewer" :on-close="() => ((showViewer = false), move())" :url-list="[qrImgage]" />
  </div>
  <!-- #endregion -->
</template>
<script>
export default {
  name: 'DirectAttention',

  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      showViewer: false, // 是否显示图片预览
      qrImgage: require('@/assets/images/footer/app.png')
    }
  },
  methods: {
    // 停止页面滚动
    stopMove() {
      const m = e => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },

    // 开启页面滚动
    move() {
      const m = e => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-image-viewer__img {
    width: 3rem;
    height: 3rem;
  }
}
.direct_attention {
  flex: 1.02;
  position: sticky;
  top: 1rem;
  margin-left: 0.1rem;
  background: linear-gradient(180deg, #ffffff 36%, #e4effd);
  border: 0.01rem solid rgba(52, 117, 241, 0.26);
  padding: 0.2rem 0.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .direct_title {
    position: relative;
    padding-left: 0.1563rem;
    font-size: 0.19rem;
    color: #666666;
  }
  .direct_title:before {
    content: '';
    position: absolute;
    top: 0.018rem;
    left: 0.0521rem;
    width: 0.03rem;
    height: 0.2rem;
    background: #4573e7;
  }
  .direct_icon {
    background: url('@/assets/images/detail_icon.png');
    --icon-size: 6.7rem;
    width: calc(0.25 * var(--icon-size));
    height: calc(0.16 * var(--icon-size));
    margin: auto;
    background-size: 100% 100%;
  }
  .direct_content {
    color: #666666;
    margin: auto;
    padding: 0rem 0.11rem 0.14rem;
    font-size: 0.12rem;
    line-height: 0.2rem;
    text-align: justify;
  }
  .direct_btn {
    width: max-content;
    margin: auto;
    color: #ffffff;
    font-size: 0.15rem;
    text-align: center;
    background: #1f72dd;
    border-radius: 0.05rem;
    padding: 0.1rem 0.25rem;
    cursor: pointer;
  }
  .direct_btn:hover {
    background: #1966ca;
  }
  .direct_qr_code {
    --qrcode-size: 2.2rem;
    width: var(--qrcode-size);
    height: var(--qrcode-size);
    margin: 0.2rem auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
