import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMfQ/Rwvj8nrTWj5qyrjA6hL7J2EaQRt\n' +
  'GWlhy4dc2+j4aaXxOoRlx0a4s3RY0zQpR0/NhE4FYDdIO3FDk/hUjUECAwEAAQ=='


const privateKey = 'MIIBVQIBADANBgkqhkiG9w0BAQEFAASCAT8wggE7AgEAAkEAx9D9HC+PyetNaPmr\n' +
  'KuMDqEvsnYRpBG0ZaWHLh1zb6PhppfE6hGXHRrizdFjTNClHT82ETgVgN0g7cUOT\n' +
  '+FSNQQIDAQABAkB5S3UKchjAAvWHvt9X9lyXAhfNer5hMHUXkoUBU64u2eJ077xC\n' +
  'NKkiDHqp+T81bZVUEBoYtdz8ECs2MLGPxxodAiEA5LWRroJIXZo7EONTF2moWSWV\n' +
  '3tIBLdDhbMcAIstGzK8CIQDfqNJ+EG7ewJ1/ARfl7CcydFacNN2pAJZkRJr4qPQh\n' +
  'DwIhAKbSVaffed9kjy7bUZFjTRtxUecMcTW1BaitYsj+Uu8bAiEA0eHfBaXuJOKj\n' +
  '6VbhUkX10ocR76w8hiei+ZzXwJjr30kCID+NCrXLCQkbBkSrYo8wSXD8kpm1tfi/\n' +
  '5hplX2Jt0Hph'

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

