import http from '@/utils/request'
import { encrypt } from '@/utils/jsencrypt.js'

export const loginApi = {
    sendSms: (data) => {
        return http({
            url: 'system/sms/sendSms',
            method: 'POST',
            data
        })
    },
    login: ({username,code,type}) => {
        return http({
            url: 'auth/app/login',
            method: 'POST',
            headers: {
                isToken: false
              },
            data: {
                username: encrypt(username + ''),
                code: encrypt(code + ''),
                type
            }
        })
    },

    checkVerificationCode: ({ username, code, type }) => {
        return http({
            url: 'auth/app/checkVerificationCode',
            method: 'POST',
            data: {
                username: encrypt(username + ''),
                code: encrypt(code + ''),
                type
            },
            headers: {
                isToken: false
            },
        })
    },

    getInfo: () => {
        return http({
            url: 'system/user/profile',
            method: 'GET'
        })
    }

}
