import http from '@/utils/request'

export const firmApi = {
  // 企业认证申请
  corpCertRequest: data => {
    return http({
      url: 'system/enterprise/apply',
      method: 'POST',
      data
    })
  },

  // 是否存在申请
  isApplyFor: () => {
    return http({
      url: 'system/enterprise/getApplyStatus',
      method: 'GET'
    })
  },

  // 已存在企业 - 个人加入企业申请
  indCorpApplication: data => {
    return http({
      url: 'system/enterpriseApply/apply',
      method: 'POST',
      data
    })
  },

  // 更新企业信息
  updateInfo: data => {
    return http({
      url: 'system/enterprise/update',
      method: 'POST',
      data
    })
  }
}
