import axios from 'axios';
import { Notification } from 'element-ui'

import { getToken } from '@/utils'
import errorCode from '@/utils/errorCode'
 
let num = 0
// 创建 axios 实例
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json',  // 设置请求头
    // 'Tenant-Id': 13
  },
});

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false
    num = 0
    config.headers['Tenant-Id'] = 13
    config.headers['Authorization'] = isToken ? '' : 'Bearer ' + getToken()
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  },
);

// 响应拦截器
http.interceptors.response.use(
  response => {
    num++
    // 对响应数据进行处理，例如提取有效数据，处理错误等
    // return Promise.reject(response.status)
    const code = response.data.code || 200
    const msg = errorCode[code] || response.data.msg || errorCode['default']

    if (code == 500 && num === 1) {
      Notification.error({
        title: msg
      })
      return Promise.reject(new Error(msg))
    } else if (code != 200 && num === 1) {
      Notification.error({
        title: msg
      })
      return Promise.reject('error')
    } else {
      return response.data;
    }
  },
  error => {
    // 处理响应错误
    return Promise.reject(error);
  },
);

export default http;