const getters = {
  userInfo: state => state.auth.userInfo,
  token: state => state.auth.token,
  navigation: state => state.navigation.globalHeaderNav,
  firmFormData: state => state.auth.firmFormData,
  enterpriseData: state => state.auth.enterpriseData,
  infoList: state => state.auth.infoList
}

export default getters
