<template>
  <div class="layout">
    <!-- 普通的官网布局 -->
    <df-header />
    <router-view />
    <df-footer />
  </div>
</template>

<script>
import { DfHeader, DfFooter } from './components'
export default {
  name: 'XCLayout',
  components: { DfHeader, DfFooter }
}
</script>
<style lang="scss" scoped>
.layout {
  position: relative;
  min-height: 100vh;
  padding-bottom: 1.7813rem;
}
</style>
