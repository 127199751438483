import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// rem配置文件
import './utils/rem'
// 自动注册全局组件
import './requireComponent'
// 自动导入element组件
import element from './utils/auto-import'
import '@/assets/style/element-variables.scss'
// 工具类库
import Lodash from 'lodash'

// 全局工具函数
import { getToken, delay, debounce, isValidPhoneNumber } from '@/utils'
// 字典数据组件
import DictData from '@/components/DictData'
DictData.install()

Vue.prototype.$lodash = Lodash
Vue.prototype.$getToken = getToken
Vue.prototype.$delay = delay
Vue.prototype.$debounce = debounce
Vue.prototype.$isValidPhoneNumber = isValidPhoneNumber

Vue.config.productionTip = false

Vue.use(element)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
