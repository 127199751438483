<template>
  <div class="df_footer">
    <div class="footer">
      <div class="footer-left">
        <div class="footer-title">快速导航</div>
        <div class="footer-menu">
          <div class="footer-menu-left">
            <router-link class="footer-menu-item" to="/notice">通知公告</router-link>
            <router-link class="footer-menu-item" to="/work">工作动态</router-link>
          </div>
          <div class="footer-menu-center">
            <router-link class="footer-menu-item" to="/active">品牌活动</router-link>
            <router-link class="footer-menu-item" to="/consult">公益咨询</router-link>
          </div>
          <div class="footer-menu-right">
            <router-link class="footer-menu-item" to="/work_point">服务体系</router-link>
            <router-link class="footer-menu-item" to="/about">关于我们</router-link>
          </div>
        </div>
      </div>
      <div class="footer-center">
        <div class="footer-title">联系我们</div>
        <div class="contact-info">
          <p>联系电话：0531-88188918</p>
          <p>联系电话：0531-88188977</p>
          <p>邮箱：sgxjfwzx@jn.shandong.cn</p>
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-title">关注公众号</div>
        <div class="code">
          <el-popover placement="top" width="200" trigger="hover">
            <img style="width: 180px; height: 180px" src="@/assets/images/footer/app.png" />
            <div class="qr-code" slot="reference">
              <img src="@/assets/images/footer/app.png" />
              <div class="qr_label">微信公众号</div>
            </div>
          </el-popover>
          <el-popover placement="top" width="200" trigger="hover">
            <img style="width: 180px; height: 180px" src="@/assets/images/footer/tiny.jpg" />
            <div class="qr-code" slot="reference">
              <img src="@/assets/images/footer/tiny.jpg" />
              <div class="qr_label">小程序</div>
            </div>
          </el-popover>
          <el-popover placement="top" width="200" trigger="hover">
            <img style="width: 180px; height: 180px" src="@/assets/images/footer/wechat.png" />
            <div class="qr-code" slot="reference">
              <img src="@/assets/images/footer/wechat.png" />
              <div class="qr_label">企业微信</div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="footer-info">
      <div>@2024济南市中小企业公共服务中心</div>
      <div class="info-center">
        <span>承办单位：济南市中小企业公共服务中心</span>
        <span>
          备案号：
          <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP10013785号-4</a>
        </span>
      </div>
      <div class="footer-list">
        鲁公网备号：
        <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37010102001087">37010102001087</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DfFooter'
}
</script>

<style lang="scss" scoped>
.df_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  height: 1.7813rem;
  background: #3b3e4e;
  color: #c1d9dd;
  .footer {
    width: 13.3rem;
    height: 1.3594rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0.0781rem 0;
    margin: 0 auto;
    font-size: 0.11rem;
    box-sizing: border-box;

    .footer-title {
      position: relative;
      line-height: 0.2604rem;
      margin-bottom: 0.1563rem;
      border-bottom: 1px solid #434552;
      color: #fff;
    }

    .footer-title::after {
      content: '';
      width: 0.1302rem;
      height: 0.0104rem;
      background-color: #707178;
      position: absolute;
      left: 0;
      top: 0.2604rem;
    }

    .footer-left {
      .footer-menu {
        display: flex;
        flex-direction: column;

        .footer-menu-center {
          margin: 0.1042rem 0;
        }

        .footer-menu-item {
          margin-right: 0.2083rem;
          color: #c1d9dd !important;
          text-decoration: none;
        }
      }
    }

    .footer-right {
      .code {
        display: flex;
        justify-content: space-around;

        .qr-code {
          margin-right: 0.1042rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            width: 0.625rem;
            height: 0.625rem;
            margin-bottom: 0.1042rem;
            cursor: pointer;
          }
          .qr_label {
            width: 0.77rem;
            text-align: center;
          }
        }
      }
    }
  }

  .footer-info {
    width: 16rem;
    margin: 0 auto;
    border-top: 1px solid #434552;
    height: 0.4219rem;
    line-height: 0.4219rem;
    display: flex;
    justify-content: space-around;
    // padding: 0 1.0417rem 0 .2604rem;
    font-size: 0.11rem;

    a {
      text-decoration: none;
      color: #9c9bdd;
    }

    .info-center {
      span:first-child {
        margin-right: 0.5208rem;
      }
    }
    .footer-list {
      background: url('@/assets/images/footer/j.png') no-repeat left center;
      padding-left: 0.1823rem;
      background-size: 0.1563rem 0.1563rem;
    }
  }
}
</style>
