<!--
  该组件用于 自定义地点 或 地图选点
  必传参数：address (String) 地址信息   {formattedAddress: '地址名称', lnglat: [坐标], addressType: '类型'}
 -->
<template>
  <div class="MapContainer">
    <template v-if="form.addressType === '0'">
      <el-input style="width: 100%" v-model="form.formattedAddress" :placeholder="placeholder" @input="inputAddress">
        <el-tooltip slot="append" effect="dark" content="切换为地图选点" placement="top-end">
          <el-button icon="el-icon-map-location" @click="changeType"></el-button>
          <!-- <i slot="suffix" class="el-input__icon el-icon-map-location" style="color: #3475ef"></i> -->
        </el-tooltip>
      </el-input>
    </template>

    <template v-else-if="form.addressType === '1'">
      <el-autocomplete style="width: 100%; margin-bottom: 15px" v-model="form.formattedAddress" :fetch-suggestions="searchTip" :placeholder="placeholder" @select="handleSelect">
        <el-tooltip slot="append" effect="dark" content="切换为自定义地址" placement="top-end" v-if="ableSwitch">
          <el-button icon="el-icon-edit" @click="changeType"></el-button>
        </el-tooltip>
      </el-autocomplete>
      <div id="container"></div>
    </template>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'

window._AMapSecurityConfig = { securityJsCode: 'eec7a718807f2f7efe2dfe05f2f17391' }
export default {
  name: 'FormMapContainer',
  props: {
    value: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    ableSwitch: {
      type: Boolean,
      default: true
    } // 是否可以切换 地图选点/自定义地点(不可切换的就是默认地图选点)
  },
  data() {
    return {
      map: null,
      markers: '',
      autoComplete: null,
      aMap: null,
      center: [117.128, 36.662],
      form: { formattedAddress: '', lnglat: [], addressType: '' }, // addressType   0 自定义地点 1 地图选点
      isFirst: true
    }
  },
  mounted() {
    const obj = JSON.parse(this.address || '{}')
    this.center = obj.lnglat?.length === 2 ? obj.lnglat : [117.128, 36.662]
    this.form = obj.lnglat ? obj : { formattedAddress: '', lnglat: [], addressType: this.ableSwitch ? '0' : '1' } // 是否可以切换 地图选点/自定义地点(不可切换的就是默认地图选点)
    !this.form.addressType && (this.form = { ...this.form, addressType: '1' }) // 自定义地点是新加的，为兼容之前的地址，没有地址类型的设为 地图选点
    this.form.addressType === '1' && this.$nextTick(() => this.initMap()) // 地图选点，加载地图
  },
  methods: {
    // 自定义地址输入
    inputAddress() {
      if (this.form.formattedAddress !== '') {
        this.form.lnglat = []
        this.$emit('changePosition', this.form)
        this.$emit('input', this.form.formattedAddress)
      } else {
        // 若没东西，formattedAddress给空
        this.$emit('changePosition', { formattedAddress: '', lnglat: [], addressType: '0' })
        this.$emit('input', '')
      }
    },
    // 切换地址类型
    changeType() {
      // 0 自定义地点 1 地图选点
      this.form.addressType = this.form.addressType === '0' ? '1' : '0'
      this.$emit('changePosition', this.form) // 切换完地址向父组件同步一下
      this.$emit('input', this.form.formattedAddress)
      this.form.addressType === '1' && this.$nextTick(() => this.initMap()) // 地图选点时加载地图
    },
    initMap() {
      AMapLoader.load({
        key: 'c262408864fcb76f5f0dc0462bb98d74', //Key
        version: '2.0', //JSAPI 的版本
        plugins: ['AMap.Geolocation', 'AMap.Geocoder', 'AMap.PlaceSearch'] //插件
      })
        .then(AMap => {
          this.aMap = AMap
          /* map实例 */
          this.map = new AMap.Map('container', { viewMode: '3D', center: this.center, zoom: 12 })
          /* 获取当前定位 */
          if (!this.form.lnglat.length) {
            this.getPosition(AMap)
          } else this.setMarkers(AMap, this.form.lnglat)
          /* 点击获取坐标  禁用时不让点击 */
          !this.disabled && this.map.on('click', e => this.analyze(AMap, [e.lnglat.lng, e.lnglat.lat]))
        })
        .catch(e => console.log(e))
    },
    /* 获取当前位置 */
    getPosition(AMap) {
      AMap.plugin('AMap.Geolocation', () => {
        const geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 10000, // 设置定位超时时间，默认：无穷大
          offset: [10, 20], // 定位按钮的停靠位置的偏移量
          zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          position: 'RB' //  定位按钮的排放位置,  RB表示右下
        })
        geolocation.getCurrentPosition()
        this.map.addControl(geolocation)
      })
    },
    /* 设置标记 */
    setMarkers(AMap, lnglat) {
      this.map.remove(this.markers)
      const marker = new AMap.Marker({
        position: lnglat,
        offset: new AMap.Pixel(-15, -22),
        icon: '//www.chuangyitong.cn/app_statics/images/common/markers.png',
        title: this.form.formattedAddress
      })
      this.markers = marker
      this.map.add(this.markers)
      this.$emit('changePosition', this.form)
      this.$emit('input', this.form.formattedAddress)
    },
    /* 坐标逆解析 */
    analyze(AMap, lnglat) {
      const geocoder = new AMap.Geocoder({ extensions: 'all' })
      geocoder.getAddress(lnglat, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.regeocode) {
            const { formattedAddress } = result.regeocode
            this.form.formattedAddress = formattedAddress
            this.form.lnglat = lnglat
            this.setMarkers(AMap, lnglat)
          }
        }
      })
    },
    /* 搜索 */
    searchTip(queryString, cb) {
      cb([])
      if (queryString) {
        const placeSearch = new this.aMap.PlaceSearch({ city: '全国' })
        placeSearch.search(this.form.formattedAddress, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            if (result && result.poiList && result.poiList.pois) {
              result.poiList.pois.forEach(item => (item.value = item.name))
              cb(result.poiList.pois)
            }
          }
        })
      }
    },
    /* 选中标记 */
    handleSelect(val) {
      const lnglat = [val.location.lng, val.location.lat]
      this.map.setZoomAndCenter(17, lnglat)
      this.form.lnglat = lnglat
      this.setMarkers(this.aMap, lnglat)
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 2rem;
}
</style>
