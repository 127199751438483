import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

/* Layout */
import Layout from '@/layout'
import UserLayout from '@/layout/userLayout'

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'HomeIndex',
        component: () => import('@/views/index')
      }
    ]
  },
  {
    path: '/notice',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'NoticeIndex',
        component: () => import('@/views/notice')
      },
      {
        path: '/notice/details',
        name: 'NoticeDetails',
        component: () => import('@/views/notice/details.vue')
      },
      {
        path: '/notice/policy-detail',
        name: 'PolicyDetail',
        component: () => import('@/views/notice/policy-detail.vue')
      }
    ]
  },
  {
    path: '/work',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'WorkIndex',
        component: () => import('@/views/work')
      },
      {
        path: '/work/details',
        name: 'WorkDetails',
        component: () => import('@/views/work/details.vue')
      }
    ]
  },
  {
    path: '/active',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'ActiveIndex',
        component: () => import('@/views/active')
      },
      {
        path: '/active/more',
        name: 'ActiveMore',
        component: () => import('@/views/active/more.vue')
      },
      {
        path: '/active/search',
        name: 'ActiveSearch',
        component: () => import('@/views/active/search.vue')
      },
      {
        path: '/active/details',
        name: 'ActiveDetails',
        component: () => import('@/views/active/details.vue')
      }
    ]
  },
  {
    path: '/consult',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'ConsultIndex',
        component: () => import('@/views/consult')
      }
    ]
  },
  {
    path: '/work_point',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'WorkPointIndex',
        component: () => import('@/views/work_point')
      },
      {
        path: '/work_point/more',
        name: 'WorkPointMore',
        component: () => import('@/views/work_point/more.vue')
      },
      {
        path: '/work_point/details',
        name: 'WorkPointDetails',
        component: () => import('@/views/work_point/details.vue')
      }
    ]
  },
  {
    path: '/about',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'AboutIndex',
        component: () => import('@/views/about/index.vue')
      }
    ]
  },
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user',
    children: [
      {
        path: '/user',
        name: 'UserIndex',
        meta: { title: '企业中心' },
        component: () => import('@/views/user/index.vue')
      },
      {
        path: '/authentication',
        name: 'UserAuthentication',
        meta: { title: '企业认证' },
        component: () => import('@/views/user/authentication.vue')
      },
      // {
      //   path: '/user/create_entry',
      //   name: 'UserEntry',
      //   meta: { title: '发布入口' },
      //   component: () => import('@/views/user/create_activity/index.vue')
      // },
      {
        path: '/user/create_activity',
        name: 'UserCreate',
        meta: { title: '企业活动发布' },
        component: () => import('@/views/user/create_activity/create.vue')
      }
    ]
  },
  {
    path: '/project/template/preview',
    name: 'Form',
    meta: { title: '填鸭表单', requireLogin: false },
    component: () => import(/* webpackChunkName: 'root' */ '@/views/tduck/project/template/preview.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  next()
  document.body.scrollTop = 0 // firefox
  document.documentElement.scrollTop = 0 // safari
})

export default router
