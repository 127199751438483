import Vue from 'vue'
import Vuex from 'vuex'
import { loginApi } from '@/api/login'
import { firmApi } from '@/api/firm'
import getters from './getters'
Vue.use(Vuex)

let infoList = [
  { label: '企业名称', key: 'name', value: '--' },
  { label: '电话', key: 'officialPhone', value: '--' },
  { label: '公司地址', key: 'address', value: '--' },
  { label: '统一信用代码', key: 'scCode', value: '--' },
  { label: '法人', key: 'legalPerson', value: '--' },
  { label: '注册资本', key: 'registeredCapital', value: '--' },
  { label: '企业网站', key: 'enterpriseWeb', value: '--' },
  { label: '企业微信', key: 'wecom', value: '--' },
  { label: '企业邮箱', key: 'email', value: '--' },
  { label: '主营业务', key: 'business', value: '--' },
  { label: '企业图片', key: 'picUrl', value: '--', type: 'img' },
  { label: '简介', key: 'introduct', value: '--' },
  { label: '营业执照', key: 'businessLicense', value: '--', type: 'img' }
]

const authModule = {
  state: {
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
    token: localStorage.getItem('token') || null,
    firmFormData: JSON.parse(localStorage.getItem('firmFormData')) || null,
    enterpriseData: JSON.parse(localStorage.getItem('enterpriseData')) || null,
    infoList
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    clearAuth(state) {
      state.userInfo = null
      state.token = null
      state.enterpriseData = null
      localStorage.removeItem('userInfo')
      localStorage.removeItem('token')
      localStorage.removeItem('enterpriseData')
      localStorage.removeItem('firmFormData')
    },
    saveToLocal(state, data) {
      state.firmFormData = data
      localStorage.setItem('firmFormData', JSON.stringify(data))
    },
    setEnterpriseData(state, data) {
      state.enterpriseData = data
      data.name && state.infoList.forEach(item => data[item.key] && (item.value = data[item.key])) // 每获取一次企业数据，更新infoList
      localStorage.setItem('enterpriseData', JSON.stringify(data))
    }
  },
  actions: {
    async login({ commit }, data) {
      const res = await loginApi.login(data)
      const { access_token } = res.data
      commit('setToken', access_token)
      return res
    },
    async getInfo({ commit }) {
      const res = await loginApi.getInfo()
      const { data } = res
      commit('setUserInfo', data)
      return res
    },
    // 清空登录信息
    async clearAuth({ commit }) {
      return await commit('clearAuth')
    },
    // 临时保存企业信息（编辑时的保存，未提交）
    async saveToLocal({ commit }, data) {
      return await commit('saveToLocal', data)
    },
    // 获取企业信息
    async getEnterpriseData({ commit }) {
      const res = await firmApi.isApplyFor()
      const { data } = res
      data && commit('setEnterpriseData', data)
      return res
    }
  }
}

const navigationModule = {
  state: {
    globalHeaderNav: [
      { label: '首页', value: '1', path: '/', name: '/' },
      { label: '通知公告', value: '2', path: '/notice', name: 'notice' },
      { label: '工作动态', value: '3', path: '/Work', name: 'Work' }, // 为了和 服务体系 区分开来，W 大写
      { label: '品牌活动', value: '4', path: '/active/more', name: 'active' },
      { label: '公益咨询', value: '5', path: '/consult', name: 'consult' },
      { label: '服务体系', value: '6', path: '/work_point/more', name: 'work_point' },
      { label: '关于我们', value: '7', path: '/about', name: 'about' }
    ]
  }
}

export default new Vuex.Store({
  modules: {
    auth: authModule,
    navigation: navigationModule
  },
  getters
})
