import http from '@/utils/request'

export const commonApi = {
    dicts: (dictType) => {
        return http({
            url: `/system/dict/data/type/${dictType}`,
            method: 'GET',
        })
    }
}
