<template>
  <div class="xc_swiper">
    <div :class="['swiper-container', `swiper-container_${uniqueId}`]">
      <div class="swiper-wrapper">
        <!-- 轮播内容 -->
        <slot></slot>
      </div>
    </div>
    <div :class="['swiper-button-next', `next_${uniqueId}`]"></div>
    <div :class="['swiper-button-prev', `prev_${uniqueId}`]"></div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  name: 'XCSwiper',
  data() {
    return {}
  },
  props: {
    uniqueId: {
      // 唯一标识符，一个页面使用多个后，避免联动问题
      type: String,
      default: '0'
    }
  },
  computed: {},
  mounted() {
    console.log(`swiper-container_${this.uniqueId}`)
    new Swiper(`.swiper-container_${this.uniqueId}`, {
      autoplay: true,
      slidesPerView: 4,
      slidesPerGroup: 4,
      // loop: true, // 循环模式选项
      // loopFillGroupWithBlank: true, // 在loop 模式下，将用空白slide 填充slide 数量不足的组。需要与  slidesPerGroup 一起使用。
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: `.next_${this.uniqueId}`,
        prevEl: `.prev_${this.uniqueId}`
      }
    })
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.xc_swiper {
  width: 11.25rem;
  position: relative;
  .swiper-container {
    width: 11.25rem;
  }
  // 前进回退按钮
  .swiper-button-prev,
  .swiper-button-next {
    --swiper-theme-color: #ffffff;
    ---btnsize: 0.5rem;
    --swiper-navigation-size: 0.3rem;
    width: var(---btnsize);
    height: var(---btnsize);
    border-radius: calc(var(---btnsize) / 2);
    background: rgba($color: #000000, $alpha: 0.13);
  }
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    background: rgba($color: #000000, $alpha: 0.5);
  }
}
</style>
