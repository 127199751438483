<template>
  <div class="swiper-demo">
    <div v-if="activityBanner.length" class="swiper-container df_content_transparent">
    <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in activityBanner" :key="item.id" @click="goActivity(item)">
            <img :src="filtrationImg(item.id)" alt="" />
            <div class="quote">{{item.dictLabel}}</div>
        </div>
    </div>
    <!-- 如果需要分页器 -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev swiper-button-white"></div>
    <div class="swiper-button-next swiper-button-white"></div>
</div>
  </div>
</template>
<script>
import Swiper from 'swiper';
import "swiper/css/swiper.min.css";
export default {
    name: 'LXSwiper',
    data() {
        return {}
    },
    props: {
        activityBanner: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        filtrationImg() {
        return (index) => {
            return [
            require('@/assets/images/home/yunfan.png'),
            require('@/assets/images/home/yiqi.png'),
            require('@/assets/images/home/gengduo1.png'),
            require('@/assets/images/home/alun.png')

            ][index];
        };
        },
    },
    mounted() {
        new Swiper ('.swiper-container', {
            grabCursor: true,
            slidesPerView: 5,
            centeredSlides: true,
            // spaceBetween: 30,
            loop: true, // 循环模式选项
            // 如果需要分页器
            // pagination: {
            //     el: '.swiper-pagination',
            // },
            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    },
    methods: {
        goActivity() {
            this.$router.push(`/active/more?id=1`)
        }
    }
}
</script>
<style lang="scss" scoped>

.swiper-demo {
    width: 16rem;
    margin: 0 auto;
    background: url('@/assets/images/home/activity_back.png') no-repeat;
    background-size: 100% 100%;
    padding: .38rem 0;
}
.swiper-container {
    
    .swiper-wrapper {
        width: 8.125rem;
        // transform: translate3d(-700px, 0px, 0px)!important;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        // background-position: center;
        // background-size: cover;
        text-align: center;
        font-size: .0938rem;
        transition: 300ms;
        transform: scale(0.8);

        img {
           width: 11.0833rem;
           height: 3.6042rem;
           margin-left: -0.0729rem;
        }
        .quote {
            position: absolute;
            bottom: .2083rem;
            font-size: .1875rem;
            width: 2.0833rem;
        }
    }
    .swiper-slide-active,.swiper-slide-duplicate-active{
      transform: scale(1);
      z-index: 99999;
	}

    .swiper-slide-prev, .swiper-slide-next {
        transform: scale(0.9);
        z-index: 9999;
    }
}

</style>