<template>
  <div class="df_top">
    <div class="df_img df_flex">
      <div class="df_logo"><img src="@/assets/images/logo.png" /></div>
      <div class="nav-center">
        <router-link class="item" :class="{ link_active: linkActive(route) }" v-for="route in navigation" :key="route.value" :to="{ path: route.path }">{{ route.label }}</router-link>
      </div>
      <div class="login df_flex_center">
        <div v-if="userInfo" class="login_out df_flex df_align_center">
          <el-avatar :size="30" :src="require('@/assets/images/active/logo_enterprise.png')"></el-avatar>
          <el-dropdown @command="command => (command === 'logout' ? loginOut() : viewDetails())">
            <div class="login_out_info">
              <span class="el-dropdown-link">
                {{ userInfo.userName }}
                <i class="el-icon-caret-bottom"></i>
              </span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="user">企业中心</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-else class="df_flex df_align_center">
          <div class="login_btn" @click="dialogVisible = true">登录</div>
          <div class="login_btn" @click="dialogVisible = true">注册</div>
        </div>
      </div>
    </div>
    <div class="df_banner" v-if="showBanner"><img :src="showBanner" /></div>
    <el-dialog :visible.sync="dialogVisible" width="5rem" @close="clearForm">
      <div slot="title" class="dialog_title">欢迎登录</div>
      <div class="dialog_body">
        <div class="dialog_body_input df_flex_center">
          <p class="phone">+86</p>
          <el-input v-model.number="mobileForm.username" maxlength="11" placeholder="手机号"></el-input>
        </div>
        <div class="dialog_body_input df_flex_center">
          <el-input v-model.number="mobileForm.code" maxlength="6" placeholder="验证码"></el-input>
          <p class="code" :class="{ disabled: !isSendable }" @click="isSendable && sendCode()">{{ isSending ? '发送中...' : countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}</p>
        </div>
        <div class="dialog_body_btn df_flex_center" @click="loginBtn" :style="{ background: $isValidPhoneNumber(mobileForm.username) && mobileForm.code && isChecked ? '#1d68ff' : '#bdc3d9' }">登录</div>
        <div class="dialog_body_check df_flex_center">
          <el-checkbox v-model="isChecked">
            我已阅读并同意
            <span>《用户协议》</span>
            <span>、</span>
            <span>《隐私条款》</span>
          </el-checkbox>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loginApi } from '@/api/login'
import { mapGetters } from 'vuex'

export default {
  name: 'dfHeader',
  data() {
    return {
      srcImgList: [
        { value: '1', showPath: '/', srcImg: require('@/assets/images/home.png') },
        { value: '2', showPath: '/notice', srcImg: require('@/assets/images/notice/back.png') },
        { value: '3', showPath: '/Work', srcImg: require('@/assets/images/work/back.png') },
        { value: '4', showPath: '/active/more', srcImg: require('@/assets/images/cover_active2.png') },
        { value: '5', showPath: '/consult', srcImg: require('@/assets/images/consult/back.png') },
        { value: '6', showPath: '/work_point,/work_point/more', srcImg: require('@/assets/images/work_point/back.png') },
        { value: '7', showPath: '/about', srcImg: require('@/assets/images/about/back.png') }
      ], // 封面图片列表
      dialogVisible: false, // 登录对话框
      mobileForm: {
        username: '',
        code: '',
        // 密码登录 = 0 密码登录 1 验证码登录
        type: 1
      },
      isSending: false,
      countdown: 0,
      isChecked: false // 是否勾选登录协议
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'token', 'navigation']),
    // 是否可以发送验证码
    isSendable() {
      return /^1[3456789]\d{9}$/.test(this.mobileForm.username) && !this.isSending && this.countdown === 0
    },
    linkActive() {
      return ({ name }) => {
        if (name === '/') return this.$route.path === name
        else return this.$route.path.includes(name)
      }
    },
    showBanner() {
      let srcImg = this.srcImgList.find(item => {
        return item.showPath.split(',').includes(this.$route.path)
      })?.srcImg
      return srcImg
    }
  },

  methods: {
    async sendCode() {
      if (this.countdown > 0 || this.isSending) {
        return // 防止重复点击发送
      }
      this.isSending = true
      const { code } = await loginApi.sendSms({
        phoneNumber: this.mobileForm.username,
        // 验证码类型（0注册 1 登录 2修改密码 3 解绑 4忘记密码）
        type: 1
      })
      if (code == 200) {
        this.isSending = false
        this.startCountdown()
      }
    },
    startCountdown() {
      this.countdown = 60
      const timer = setInterval(() => {
        this.countdown--
        if (this.countdown <= 0) {
          clearInterval(timer)
        }
      }, 1000)
    },

    async loginBtn() {
      const { mobileForm, $notify, $isValidPhoneNumber, $store } = this

      if (!$isValidPhoneNumber(mobileForm.username)) {
        return $notify.warning({ message: '手机号码格式有误' })
      }

      if (!mobileForm.code) {
        return $notify.warning({ message: '请输入验证码' })
      }

      if (!this.isChecked) {
        return $notify.warning({ message: '请勾选用户协议' })
      }

      try {
        const { code } = await loginApi.checkVerificationCode(mobileForm)
        if (code == 200) {
          // await this.$store.dispatch('phoneLogin', this.mobileForm.username)
          await $store.dispatch('login', mobileForm)
          await $store.dispatch('getInfo')
          $notify.success({ message: '登录成功' })

          await this.$store.dispatch('getEnterpriseData') // 登录完之后，获取企业绑定信息（活动页面可跳转到发布，要用到数据，所以提前获取）
        }
        this.dialogVisible = false
      } catch (message) {
        console.error('登录或获取用户信息时发生错误:', message)
      }
    },

    // 清空表单
    clearForm() {
      this.mobileForm = {
        username: '',
        code: '',
        type: 1
      }
      this.isChecked = false
    },

    // 退出
    loginOut(res) {
      console.log(res)
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(async () => {
        await this.$store.dispatch('clearAuth')
        this.$notify.success({ message: '退出成功' })
      })
    },

    // 查看个人信息
    async viewDetails() {
      // 如果企业有绑定信息存在  跳转个人中心 否则 跳转到完善企业信息
      if (!this.$store.getters.enterpriseData) await this.$store.dispatch('getEnterpriseData') // 获取企业绑定信息
      this.$router.push({ name: this.$store.getters.enterpriseData ? 'UserIndex' : 'UserAuthentication' })
    }
  }
}
</script>

<style lang="scss" scoped>
.df_top {
  width: 100%;
  margin: 0 auto;
  .df_img {
    width: 100%;
    background: #3475ef;
    .df_logo {
      box-sizing: border-box;
      width: 3.75rem;
      height: 0.6rem;
      padding: 0.08rem 0.58rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nav-center {
      height: 0.6rem;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        display: block;
        height: 0.6rem;
        line-height: 0.6rem;
        padding: 0 0.35rem;
        text-decoration: none;
        color: #fff;
        font-size: 0.16rem;
        font-weight: 600;
      }
      .link_active {
        font-size: 0.2rem;
        background: #2c63ca;
      }
    }
    .login {
      width: 2rem;
      &_btn {
        width: 0.6rem;
        height: 0.24rem;
        line-height: 0.24rem;
        text-align: center;
        margin: 0.04rem;
        transform: translateX(-20%);
        cursor: pointer;
        user-select: none;
        border-radius: 0.05rem;
        letter-spacing: 0.01rem;
        border: 0.001rem solid #ffffff;
        box-shadow: 0rem 0.04rem 1rem -0.02rem rgba(52, 117, 239, 0.35);
        font-size: 0.12rem;
        position: relative;
        &:nth-child(1) {
          color: #ffffff;
          background: #3475ef;
          border-color: #ffffff;
        }
        &:nth-child(1):hover {
          background: #4582f0;
        }
        &:nth-child(2) {
          color: #3475ef;
          background: #ffffff;
          border-color: #3475ef;
        }
        &:nth-child(2):hover {
          background: #efefef;
        }
        &:active::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 0.6rem;
          height: 0.24rem;
          border-radius: 0.05rem;
          background: rgba($color: #000, $alpha: 0.1);
        }
      }
    }
  }
  .df_banner {
    height: 2.92rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  ::v-deep .el-dialog {
    background-image: url('@/assets/images/bg_login.png');
    background-size: 100% 50%;
    background-repeat: no-repeat;
    border-radius: 0.18rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
    &__header {
      padding: 0.3rem 0.5rem;
    }
    &__body {
      padding: 0 1.15rem 0.35rem;
    }
  }
  .dialog {
    &_title {
      font-size: 0.2rem;
      color: #3d3d3d;
      letter-spacing: 0.02rem;
      font-weight: bold;
    }
    &_body {
      --font-size: 0.14rem;
      &_input {
        border-bottom: 0.01rem solid #bdc3d9;
        margin-bottom: 0.3rem;
        p {
          font-size: var(--font-size);
        }
        p.phone {
          width: 0.5rem;
          font-weight: bold;
          color: #3d3d3d;
          border-right: 0.01rem solid #bdc3d9;
        }
        p.code {
          width: 1.2rem;
          color: #3475ef;
          text-align: right;
          cursor: pointer;
          user-select: none;
          &:hover {
            color: #3678eb;
          }
          &:active {
            color: #0347bd;
          }
          &.disabled {
            color: #bdc3d9;
            cursor: not-allowed;
          }
          &.disabled:hover {
            color: #bdc3d9;
          }
          &.disabled:active {
            color: #bdc3d9;
          }
        }

        ::v-deep {
          .el-input {
            &__inner {
              background: none;
              height: 0.4rem;
              border: 0;
              font-size: var(--font-size);
              padding: 0.1rem;
            }
          }
        }
      }
      &_btn {
        background: #bdc3d9;
        color: #ffffff;
        font-size: var(--font-size);
        height: 0.4rem;
        border-radius: 0.06rem;
        letter-spacing: 0.1rem;
        margin: 0.3rem 0 0.2rem;
        position: relative;
        &:active::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.06rem;
          background: rgba($color: #000, $alpha: 0.1);
        }
      }
      &_check {
        --font-size: 0.11rem;
        span {
          font-size: var(--font-size);
          color: #3475ef;
          cursor: pointer;
        }
        ::v-deep {
          .el-checkbox {
            display: flex;
            align-items: center;
            &__input {
              scale: 0.8;
            }
            &__label {
              padding-left: 0.1rem;
              font-size: var(--font-size);
              letter-spacing: 0.01rem;
              color: #3d3d3d !important;
            }
          }
          .is-checked .el-checkbox__inner {
            background-color: #3475ef;
          }
        }
      }
    }
  }
  .login_out {
    color: #fff;
    .el-dropdown-link {
      padding-left: 0.1rem;
      display: inline-flex;
      cursor: pointer;
      color: #ffffff;
    }
  }

  .dialog_body_btn {
    cursor: pointer;
  }
}
</style>
