<template>
  <div class="text_content">
    <!-- 图片太大时二维码识别很慢，若processedRichText没处理完，先使用textContent -->
    <div :class="['rep_con', fontSize]" v-html="processedRichText || textContent"></div>
  </div>
</template>
<script>
import jsQR from 'jsqr'
export default {
  name: 'RichText',
  data() {
    return {
      processedRichText: '' // 处理后的_富文本内容
    }
  },
  props: {
    textContent: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: 'rep_con_fontsize_medium'
    }
  },
  computed: {},
  watch: {
    textContent: async function (newVal) {
      var imgRegex = /<img[^>]*>/g
      var imgElements = newVal.match(imgRegex) || []

      // 存储所有异步调用的Promise
      var promises = []

      // 遍历每个<img>元素进行处理
      for (let i = 0; i < imgElements.length; i++) {
        let imgElement = imgElements[i]

        // 提取src属性
        let srcRegex = /src="([^"]*)"/
        let match = imgElement.match(srcRegex)
        let src = match[1]

        // 异步调用方法识别图片并获取识别结果
        let promise = this.urlImageToqR(src)
        promises.push(promise)
      }

      // 等待所有异步调用完成
      var recognitionResults = await Promise.all(promises)

      // 替换原始富文本中的<img>元素
      for (let i = 0; i < imgElements.length; i++) {
        let imgElement = imgElements[i]
        let recognitionResult = recognitionResults[i]

        // 给图片添加class属性
        let classAttribute = 'class="' + recognitionResult + '"'
        let newImgElement = imgElement.replace(/\/?>/, ' ' + classAttribute + '/>')

        newVal = newVal.replace(imgElement, newImgElement)
      }

      // 更新计算属性的值
      this.processedRichText = newVal.replace(/font-size:\s*\d+px|line-height:\s*\d+px|background:rgb\(\d+,\d+,\d+\)|background:white|background-color: rgb\(\d+, \d+, \d+\)/g, '')
    }
  },
  mounted() {},
  methods: {
    // 识别图片是否是二维码
    urlImageToqR(url) {
      var objCanvas = document.createElement('canvas')
      var ctx = objCanvas.getContext('2d')
      var image = new Image()
      image.crossOrigin = 'Anonymous' // 设置跨域属性，确保可以加载跨域图片
      image.src = url + '?' + new Date().getTime()
      image.width = 100
      image.height = 100
      return new Promise((resolve, reject) => {
        image.onload = () => {
          ctx.drawImage(image, 0, 0, image.width, image.height)
          var imageData = ctx.getImageData(0, 0, image.width, image.height)
          const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' })
          resolve(code?.data ? 'qr_code' : '')
        }
        image.onerror = () => {
          reject(new Error('图像加载失败'))
        }
      })
    }
  }
}
</script>
<style lang="scss">
/* 富文本展示样式 */
.rep_con {
  width: 7.3rem;
  margin: 0 auto;
  text-align: left;
  // text-indent: 2em;
  * {
    font-family: 'Microsoft YaHei' !important;
  }
  video {
    width: 100%;
    height: auto;
  }
  img:not(.qr_code) {
    width: 100% !important;
    height: auto !important;
    margin: 0.2rem auto;
    display: flex;
    justify-content: center;
    text-align: center !important;
  }
  img.qr_code {
    --qr-size: 2rem;
    width: var(--qr-size) !important;
    height: var(--qr-size) !important;
  }
  p {
    text-align: justify;
    margin: 0;
  }
  p:has(img.qr_code) {
    text-indent: 0 !important;
    text-align: center !important;
    span {
      all: revert !important;
    }
  }
  table {
    margin-left: 2em;
    border-collapse: collapse;
    border: 1px solid #666666;
    font-size: 0.1094rem;
    line-height: 0.1823rem;
    text-align: center;
    th {
      background: #f5f5f5;
      color: #333;
      border: 1px solid #666666;
    }
    td {
      border: 1px solid #666666;
      padding: 0.0547rem.1563rem;
    }
  }
}
/**
 * 字体大小  small medium large
 */
.rep_con_fontsize_large {
  * {
    font-size: 0.22rem !important;
    line-height: 0.385rem !important;
  }
}
.rep_con_fontsize_medium {
  * {
    font-size: 0.17rem !important;
    line-height: 0.3rem !important;
  }
}
.rep_con_fontsize_small {
  * {
    font-size: 0.134rem !important;
    line-height: 0.24rem !important;
  }
}
</style>
