<template>
  <div class="MapContainer" :style="`width: ${width}; height: ${height}`">
    <div id="container"></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'

window._AMapSecurityConfig = { securityJsCode: 'eec7a718807f2f7efe2dfe05f2f17391' }
export default {
  name: 'MapContainer',
  props: {
    address: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '250px'
    },
    height: {
      type: String,
      default: '250px'
    }
  },
  data() {
    return {
      map: null,
      markers: '',
      autoComplete: null,
      aMap: null,
      center: [110.0, 38.0],
      form: { formattedAddress: '', lnglat: [] }
    }
  },
  mounted() {
    const obj = JSON.parse(this.address)
    this.center = obj.lnglat || [110.0, 38.0]
    this.form = obj
    this.$nextTick(() => this.initMap()) // 地图选点，加载地图
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: 'c262408864fcb76f5f0dc0462bb98d74', //Key
        version: '2.0', //JSAPI 的版本
        plugins: ['AMap.Geolocation', 'AMap.Geocoder', 'AMap.PlaceSearch'] //插件
      })
        .then(AMap => {
          this.aMap = AMap
          /* map实例 */
          this.map = new AMap.Map('container', { viewMode: '3D', center: this.center, zoom: 17 })
          /* 设置标记 */
          this.setMarkers(AMap, this.form.lnglat)
          /* 点击获取坐标 */
          this.map.on('click', e => this.analyze(AMap, [e.lnglat.lng, e.lnglat.lat]))
        })
        .catch(e => console.log(e))
    },
    /* 设置标记 */
    setMarkers(AMap, lnglat) {
      this.map.remove(this.markers)
      const marker = new AMap.Marker({
        position: lnglat,
        offset: new AMap.Pixel(-15, -22),
        icon: '//www.chuangyitong.cn/app_statics/images/common/markers.png',
        title: this.form.formattedAddress
      })
      this.markers = marker
      this.map.add(this.markers)
      this.$emit('changePosition', this.form)
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}
</style>
