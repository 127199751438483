<template>
  <div class="layout" :style="{ backgroundImage: `url(${require('@/assets/images/user/bg_layout1.png')})` }">
    <div class="layout_header df_flex">
      <div class="layout_header_logo">
        <img class="xc_imgsize" :src="require('@/assets/images/user/logo_layout.png')" alt="" />
      </div>
      <div class="layout_header_title">{{ $route.meta.title }}</div>
      <div v-if="['企业中心', '企业认证'].includes($route.meta.title)" class="layout_header_nav df_flex_center">
        <router-link class="layout_header_nav_item" v-for="route in $store.state.navigation.globalHeaderNav" :key="route.value" :to="{ path: route.path }">{{ route.label }}</router-link>
      </div>
      <div class="layout_header_login df_flex_center" @click="loginOut">
        <div class="layout_header_login_logo">
          <img class="xc_imgsize" :src="require('@/assets/images/active/logo_enterprise.png')" alt="" />
        </div>
        退出登录
      </div>
    </div>
    <div class="layout_content">
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLayout',
  methods: {
    async loginOut() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(async () => {
        await this.$store.dispatch('clearAuth')
        this.$notify.success({ message: '退出成功' })
        this.$router.push({ name: 'HomeIndex' })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.layout {
  height: 100vh;
  overflow: hidden;
  background-color: #e8ecf1;
  background-size: 100% 20vw;
  background-repeat: no-repeat;
  &_header {
    justify-content: space-between;
    align-items: center;
    padding: 0.17rem 0.3rem;
    &_logo {
      width: 2rem;
      height: 0.35rem;
    }
    &_title {
      font-weight: bold;
      font-size: 0.2rem;
      color: #3d3d3d;
      margin-right: auto;
      margin-left: 0.2rem;
      padding-left: 0.2rem;
      border-left: 0.01rem solid #9299b4;
    }
    &_nav {
      margin: 0 auto 0 -1.8rem;
      &_item {
        display: block;
        padding: 0 0.2rem;
        text-decoration: none;
        color: #3d3d3d;
        font-size: 0.16rem;
        letter-spacing: 0.003rem;
        font-weight: bold;
      }
    }
    &_login {
      font-size: 0.14rem;
      letter-spacing: 0.008rem;
      cursor: pointer;
      &_logo {
        --size: 0.3rem;
        width: var(--size);
        height: var(--size);
        margin-right: 0.1rem;
      }
    }
  }
  &_content {
    width: 10.5rem;
    margin: 0 auto;
    padding: 0.3rem 0;
  }
}
</style>
